export const onServiceWorkerUpdateReady = () => {
  // const answer = window.confirm(
  //   "Databowl has been updated. Reload to display the latest version?"
  // );
  // if (answer === true) {
  //   window.location.reload();
  // }
  window.location.reload();
};

export const onRouteUpdate = ({ location }) => {
  var mainNavHeight = 0;

  if (typeof window !== `undefined`) {
    if (location && location.hash) {
    

      if (window.location.hash && window.location.hash.split("=")) {
        const hash = window.location.hash.substring(1).split("=");
        if (
          [
            "confirmation_token",
            "email_change_token",
            "invite_token",
            "recovery_token",
          ].includes(hash[0])
        ) {
          window.location = `/admin/${window.location.hash}`;
        }
      } else {
        const item = document?.querySelector(location.hash)?.offsetTop;

        window.scrollTo({
          top: item - mainNavHeight,
          behavior: "smooth",
        });
      }
    }
    else{
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }
};

export const shouldUpdateScroll = () => {
  // return false;
};
